import React from "react";
import Flex from "components/flex";
import Text from "components/text";

const AgencyInactiveBanner = () => {
  return (
    <Flex
      style={{
        background: "#FF0000",
        padding: "10px 0"
      }}
      alignItems={"center"}
      width={"100%"}
    >
      <Text
        textAlign={"center"}
        color={"#FFF"}
        padding={"10px 0"}
        fontSize={"18px"}
        fontWeight={2}
        width={"100%"}
      >
        {"This organization’s account is currently inactive"}
      </Text>
    </Flex>
  );
};

export default AgencyInactiveBanner;
